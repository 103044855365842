<template>
  <DataTable
    :value="users"
    class="limitWidthCenter"
    v-model:expandedRows="expandedRows"
    responsiveLayout="scroll"
    :scrollable="true"
    scrollHeight="flex"
    :paginator="true"
    :rows="50"
    v-model:filters="filter"
    :globalFilterFields="['email', 'name', 'companies']"
    :loading="loading"
    dataKey="userId"
  >
    <template #loading>Loading Applications</template>
    <template #header>
      <div class="fw-header">
        <div>
      <h6 class="mt-2 ml-2"><span class="p-menuitem-icon fas fa-users" aria-hidden="true"></span> Application Users</h6>
      </div>

      <div class="w-full">
        <span class="p-input-icon-left w-full">
          <i class="fa fa-search" />
          <InputText
            v-model="filter['global'].value"
            placeholder="Search"
            :class="'fullWidthInput'"
            type="search"
          />
        </span>
      </div>
      <div>
          <button
            class="p-button p-component p-button-icon-only p-button-rounded-light ml-2"
            type="button"
            aria-haspopup="true"
            aria-controls="overlay_menu"
          >
            <span class="fa fa-filter p-button-icon" aria-hidden="true"></span>
          </button>

      </div>
</div>

    </template>
    <Column :expander="true" headerStyle="width: 3rem" class="icon-col" />
    <Column field="name" header="Name" :sortable="true">
      <template #body="{data}"> {{ data.userId }} {{ data.name }} </template>
    </Column>
    <Column field="email" header="email" :sortable="true"></Column>

    <Column field="count" header="Profiles"  class="icon-col" :sortable="true">
      <template #body="{data}">
        <span v-if="data.count">
          <Badge :value="data.count" />
        </span>
        <Button
          v-else
          @click="confirmDeleteUser(data)"
          class="p button p-component p-button-icon-only button-delete"
          icon="fas fa-times"
        />
      </template>
    </Column>
    <Column field="lastLogin" class="date-col" header="Last Login" :sortable="true"></Column>

    <template #expansion="{data}">
      <ul>
        <li v-for="company in data.companies" :key="company">
          <router-link :to="{path:'/company/' +company.profileId}"
            >{{ company.profileId }} -- {{ company.coc }}--  {{ company.company }}</router-link
          >
        </li>
      </ul>
      <h6 v-if="!data.count">
        No profiles registered.
      </h6>
    </template>
  </DataTable>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import Badge from "primevue/badge";

export default {
  name: "APP Users",
  components: { Badge },
  data() {
    return {
      users: [],
      expandedRows: [],
      loading: false,
      filter: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS }
      }
    };
  }, //data()
  created() {
    this.loading = true;
    this.axios.get("/AppUsers").then(response => {
      this.loading = false;
      this.users = response.data;
    });
  }, //created()
  methods: {
    confirmDeleteUser(user) {
      console.log("delete", user);
      this.$swal({
        title: "Delete " + user.name,
        text: user.email + " will be deleted permanently?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Confirm Delete"
      }).then(response => {
        console.log(response);
        if (response.isConfirmed) {
          this.axios.delete("/AppUser/" + user.userId).then(response => {
            console.log("delete response", response);
            console.log(this.users.indexOf(user));
            if (response.data?.error){
              return this.$swal({
                title: "Delete " + user.name,
                text: response.data.message,
                icon: "error",
              })
            }
            this.users.splice(this.users.indexOf(user), 1);
          });
        }
      });
    }
  } //methods
};
</script>

<style></style>
